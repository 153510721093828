/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

.container.has-open-drawer {
    overflow-y: hidden;
}

html {
    scroll-behavior: smooth;
    overscroll-behavior: auto;
}
